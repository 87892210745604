import { VStack } from "goi_common";
import { useRouter } from "next/router";
import React, { useMemo } from "react";

import { useDisclosure } from "@chakra-ui/react";

import Header from "./Header";
import Menu from "./Menu";
import TossHeader from "./TossHeader";

interface LayoutProps {
  children: React.ReactNode;
  showConditional?: boolean;
}

export default function Layout({ children, showConditional }: LayoutProps) {
  const router = useRouter();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const isRouteIncludeToss = router.route.includes("toss");

  const isHeaderHidden = useMemo(() => {
    if (showConditional) {
      return false;
    }

    return (
      router.route === "/login" ||
      router.route.includes("contract-check/") ||
      router.route.includes("contract-confirm") ||
      router.route === "/register-pay-method" ||
      router.route === "/register-pay-method/failure" ||
      router.route.includes("/event") ||
      router.route.includes("closed") ||
      router.route === "/content/feeds/sangjo" ||
      router.route === "/content/feeds/ceo-youtube" ||
      router.route.includes("review/") ||
      router.route.includes("content/feeds/tip") ||
      router.route === "/content/feeds/youtube/luckyquiz" ||
      isRouteIncludeToss
    );
  }, [router, showConditional]);

  return (
    <>
      {!isHeaderHidden && <Header onOpen={onOpen} />}
      {isRouteIncludeToss &&
        !router.route.includes("registration/toss-payment") && (
          <TossHeader onOpen={onOpen} />
        )}

      <VStack>{children}</VStack>

      <Menu isOpen={isOpen} onClose={onClose} />
    </>
  );
}
